li a{
  cursor: pointer;
}

.homeBanner{
  background-image: url('./assets/banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%; 
    height: 85vh
}