
.sourcing-partners-section {
    background: linear-gradient(rgba(2, 48, 71, 0.7), rgba(2, 48, 71, 0.7)),url('../../assets/sourcePartnerBanner.jpg');
    background-size: cover;
    background-position: center;
    padding: 1rem 2rem;
}


.btn-outline-success {
    background-color: #a2c700;
    color: #fff;
    border-color: #a2c700;
}

.btn-outline-success:hover {
    background-color: #8ab900;
    color: #fff;
    border-color: #8ab900;
}

.bi-arrow-right {
    margin-left: 0.5rem;
}

.logo-container {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}