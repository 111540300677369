.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.phone-mockup {
    position: relative;
    width: 250px;
    height: 450px;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.phone-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
}

.phone-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #ccc;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.phone-notch {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 5px;
    background-color: #ccc;
    border-radius: 50%;
}