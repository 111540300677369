.row .buttonapplynow {
    color: #023047;
    text-decoration: none;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    font-weight: 700;
    border: 0;
    padding: 7px 15.5px;
    border: 2px solid #023047;
    font-size: 22px;
}

.row .buttonapplynow:hover {
    background-color: #023047 !important;
    color: white !important;
    border-color: white !important;
    /* box-shadow: 1px 1px 20px .1px white !important; */
}
.form-group {
    margin-bottom: 0;
}

.currency-symbol {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0B3D5B;
}

.loan-amount-input {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0B3D5B;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding-left: 5px;
    -moz-appearance: textfield;
    /* Firefox */
}

.loan-amount-input::-webkit-outer-spin-button,
.loan-amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.underline {
    border-bottom: 2px solid #0B3D5B;
    margin-top: -8px;
}
.cursor-pointer{
    cursor: pointer;
}