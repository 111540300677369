div.svg-shape svg {
    fill: white;
}

footer ul {
    list-style: none;
    padding-left: 0;
}

footer ul li .icon .iconinside {
    font-size: 50px;
    margin-right: 16px;
}

footer ul li a,
footer ul li .nav-link {
    text-decoration: none;
}

footer svg {
    font-size: 19px !important;
    margin-right: 5px !important;
    margin-top: -5px !important;
}

footer .widget-title,
footer img {
    /* margin-bottom: 30px; */
}

footer .get_app {
    font-size: 24px;
    font-weight: 700;
}

footer .buttonapplynow {
    color: #023047;
    text-decoration: none;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    font-weight: 700;
    border: 0;
    padding: 14px 40.5px;
    border: 2px solid #023047;
    font-size: 22px;
}

footer .buttonapplynow:hover {
    background-color: #023047 !important;
    color: white !important;
    border-color: white !important;
    /* box-shadow: 1px 1px 20px .1px white !important; */
}

footer .buttonapply2 {
    color: #023047;
    background-color: #023047;
    border-radius: 10px;
    font-weight: 700;
    border: 0;
    padding: 10px 20px;
    border: 3px solid white;
    font-size: 22px;
    display: flex;
}

footer .buttonapply2 img {
    margin-right: 40px;
}

footer .buttonapply2:hover .footerbuttontext {
    text-decoration: underline !important;
}

footer h6 {
    font-size: 11px;
    font-weight: 400;
    color: #F9F8F6;
}

footer .f-b {
    font-size: 16px;
    font-weight: 400;
}

footer .widget-title {
    display: inline-block;
    position: relative;
    z-index: 1;
}

footer .f-item.about {
    margin-right: 30px;
}

footer .address h5 {
    margin-bottom: 10px;
    /* text-transform: capitalize; */
    font-size: 17px;
}

footer .address li {
    display: flex;
}

footer .address li i {
    font-size: 30px;
    min-width: 50px;
    display: inline-block;
}

footer .address {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 15px;
}

footer .link li {
    margin-bottom: 15px;
}

footer .link li:last-child {
    margin-bottom: 0;
}

footer .link li a,
footer .link li .nav-link {
    color: #96aac1;
}

footer .link a:hover,
footer .link .nav-link:hover {
    color: #ffffff !important;
    text-decoration: underline;
}

footer .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 25px 0;
}

footer .footer-bottom li {
    display: inline-block;
}

footer .footer-bottom p {
    margin: 0;
}

footer .footer-bottom .text-right li {
    margin-left: 19px;
}

.circle-shape2 {
    position: relative;
    z-index: 1;
    cursor: pointer !important;
}



.footerbuttontext {
    margin-bottom: 0px;
    margin-top: 8px;
}