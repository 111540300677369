.contact-us-section {
    background-size: cover;
    background-position: center;
    background: linear-gradient(rgba(2, 48, 71, 0.7), rgba(2, 48, 71, 0.7)), url('../../assets/sourcePartnerBanner.jpg');
    padding: 1rem 2rem;
}

.icon-container {
    background-color: #9cc93f;
    padding: 20px;
    border-radius: 50%;
    color: white;
}
