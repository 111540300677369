.offerings-section {
    background-color: #FFFFFF;
    padding: 60px 0;
}

.Explore-card {
    background: linear-gradient(90deg, rgba(162, 189, 0, 0.3) 0%, rgba(2, 48, 71, 0.2) 100%);
    border-radius: 20px;
    
    border: none;
}

