.product-banner{
    width: 100%; height: 50vh; object-fit: cover
}





@media screen and (max-width: 768px) {
    .product-banner{
        height: 30vh;
    }
}